import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import './navigation.css';
import {ReactComponent as ReactLogo} from './puffinlogo.svg';
import { fabric } from "fabric";

class Visualization extends React.Component {
	
	canvas;
	isDragging;
	
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			maxDepth: 2,
			maxChildren: 5,
			transition: true
		}
		this.wrapper = React.createRef()
		this.resizeCanvas = this.resizeCanvas.bind(this)
		this.getFabricOwlClass = this.getFabricOwlClass.bind(this)
		this.drawDiagram = this.drawDiagram.bind(this)
		this.intersect = this.intersect.bind(this)
		this.getAngle = this.getAngle.bind(this)
		this.getIntersectionPoint = this.getIntersectionPoint.bind(this)
		this.getAssociation = this.getAssociation.bind(this)
		this.updateAssociation = this.updateAssociation.bind(this)
		this.drawRow = this.drawRow.bind(this)
		this.handleDepthChange = this.handleDepthChange.bind(this)
		this.handleChildrenChange = this.handleChildrenChange.bind(this)
	}
	
	resizeCanvas() {
		if (this.wrapper != null && this.wrapper.current != null)
			this.canvas.setWidth(this.wrapper.current.offsetWidth)
	}
	
	getFabricOwlClass(classData) {
		// set class name
		if (classData["name"] == undefined)
			classData["name"] = classData["iri"]
		var name = new fabric.Text(classData["name"], {
			fontSize: 16,
			fontWeight: 'bold',
			fontFamily: 'Segoe UI'
		});

		var attText = "";

		for (var x = 0; x < classData["attributes"].length; x++) {
			var att = Object.keys(classData["attributes"][x])[0];
			if (x != 0) {
				attText += "\n";
			}
			attText += att + " : " + classData["attributes"][x][att];
		}

		var attributes = new fabric.Text(attText, {
			top: 30,
			fontSize: 11,
			fontFamily: 'Segoe UI',
			lineHeight : 1.5
		});

		// group together as class object
		var owlClass = new fabric.Group([name, attributes], { id : classData["IRI"], originY: 'top', objectCaching: false});

		// TODO Refactor the padding out of the group
		var padding = 12;

		// add a box around the class
		// add rect around group
		var border = new fabric.Rect({
			// position from group center
			left: -0.5*owlClass.width-(padding/2),
			top: -0.5*owlClass.height-(padding/2),
			width: owlClass.width+padding,
			height: owlClass.height+padding,
			stroke: 'black',
			strokeWidth: 2,
			fill: '#ffe5ad',
		});
		var header = new fabric.Line([-0.5*owlClass.width-(padding/2), -0.5*owlClass.height-(padding/2)+30, 0.5*owlClass.width+(padding/2), -0.5*owlClass.height-(padding/2)+30], {
			fill: '#696969',
			stroke: 'black',
			strokeWidth: 2,
			selectable: false,
		 });

		owlClass.add(border);
		owlClass.add(header);
		border.moveTo(0);
		header.moveTo(1);
		attributes.moveTo(2);
		name.moveTo(3);
		owlClass.addWithUpdate();
		owlClass.hasControls = false;
		owlClass.dirty = true;
		
		if (border.height > 700) {
			this.canvas.setHeight(border.height + 200)
		}
		
		return owlClass;
	}
	
		// drawing associations between different classes
	getAssociation(startObj, endObj, label, assocID) {
		var labelText = label;
		var fillColor = 'black';
		if (label === "inherits") {
			fillColor = 'white';
			labelText = '';
		}

		var line = new fabric.Line([1, 1, 0, 0], {
			fill: '#696969',
			stroke: '#696969',
			strokeWidth: 1,
			selectable: false,
		});

		var assoc = new fabric.Text(labelText, {
			fontSize: 11,
			fontFamily: 'Arial',
			lineHeight : 1.5,
			originX: 'left',
			originY: 'top'
		});

		// triangle
		var triangle = new fabric.Triangle({
			fill: fillColor,
			stroke: '#696969',
			strokeWidth: 1,
			height: 11,
			width: 11,
			originX: 'center'
		});

		var association = new fabric.Group([line, assoc, triangle], { id : assocID});
		association.set({
			originX: "center",
			originY: "center"
		});

		association.selectable = false;
		return association;
	}

	// https://jsfiddle.net/ka7nhvbq/2/
	// update associations on drag of the objects
	updateAssociation(assoc, startObj, endObj, top, left, grouped) {
		// if there is an offset
		var pos1 = startObj.getCenterPoint();
		var pos2 = endObj.getCenterPoint();

		// calculate the connection sides
		var intersection = this.getIntersectionPoint(pos1, pos2, endObj.width / 2, endObj.height / 2);

		if (intersection) {
			assoc.item(1).originX = 'right';
			if (intersection.direction == 2)
				assoc.item(1).originX = 'left';
			pos2.x = intersection.x;
			pos2.y = intersection.y;
			assoc.item(0).set({ 'x1': pos1.x-left, 'y1': pos1.y, 'x2': pos2.x, 'y2': pos2.y });

			var assocPoint = pos1.lerp(pos2, 0.8);
			assoc.item(1).top = assocPoint.y;
			assoc.item(1).left = assocPoint.x;
			assoc.item(2).angle = this.getAngle(pos1, pos2);
			assoc.item(2).top = pos2.y;
			assoc.item(2).left = pos2.x;
		}
		assoc.dirty = true;
		var object = fabric.util.object.clone(assoc.item(0));
		object.set("top", object.top);
		object.set("left", object.left);
		this.canvas.add(object);
		var object = fabric.util.object.clone(assoc.item(2));
		object.set("top", object.top);
		object.set("left", object.left);
		this.canvas.add(object);
		this.canvas.remove(assoc);
	}

	getIntersectionPoint(pos1, pos2, width, height) {
		// check each of the four sides making up the endObj
		var bLeft = { 'x': pos2.x - width + 6, 'y': pos2.y + height};
		var bRight = { 'x': pos2.x + width + 6, 'y': pos2.y + height};
		var tLeft = { 'x': pos2.x - width + 6, 'y': pos2.y - height};
		var tRight = {'x': pos2.x + width + 6, 'y': pos2.y - height};
		// checking from left, top, right, bottom
		var left = this.intersect(pos1.x, pos1.y, pos2.x, pos2.y, bLeft.x, bLeft.y, tLeft.x, tLeft.y);
		if (left) {
			left.direction = 0;
			return left;
		}
		var top = this.intersect(pos1.x, pos1.y, pos2.x, pos2.y, tLeft.x, tLeft.y, tRight.x, tRight.y);
		if (top) {
			top.direction = 1;
			return top;
		}
		var right = this.intersect(pos1.x, pos1.y, pos2.x, pos2.y, tRight.x, tRight.y, bRight.x, bRight.y);
		if (right) {
			right.direction = 2;
			return right;
		}
		var bottom = this.intersect(pos1.x, pos1.y, pos2.x, pos2.y, bLeft.x, bLeft.y, bRight.x, bRight.y);
		if (bottom) {
			bottom.direction = 3;
			return bottom;
		}
	}

	// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
	// Determine the intersection point of two line segments
	// Return FALSE if the lines don't intersect
	intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
	  // Check if none of the lines are of length 0
		if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
			return false
		}
		let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))
	  // Lines are parallel
		if (denominator === 0) {
			return false
		}
		let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
		let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator
	  // is the intersection along the segments
		if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
			return false
		}
	  // Return a object with the x and y coordinates of the intersection
		let x = x1 + ua * (x2 - x1)
		let y = y1 + ua * (y2 - y1)
		return {x, y}
	}

	getAngle(pos1, pos2) {
		let dx = pos2.x - pos1.x;
		let dy = pos2.y - pos1.y;
		let angle = Math.atan2(dy, dx);
		angle *= 180 / Math.PI;
		angle += 90;
		return angle;
	}
	
	componentDidMount() {
		fabric.Object.prototype.objectCaching = false;
		this.drawDiagram()
	}
	
	drawRow(rowClasses, up) {
		// get the maxheight
		let maxHeight = 0
		let maxTop = 0
		for (let rc of rowClasses) {
			if (rc["visualization"].height > maxHeight) {
				maxHeight = rc["visualization"].height
				maxTop = rc["visualization"].top
			}
		}
		console.log(maxTop)
		
		// set of new row classes
		let nrc = []
		let assocs = []
		
		// draw the children
		let crc
		let width = 0
		for (let rc of rowClasses) {
			let list = []
			if (up) {
				if (rc["parents"] != null) {
					list = rc["parents"]
				}
			} else {
				if (rc["children"] != null) {
					list = rc["children"]
				}
			}

			for (let c of list) {
				console.log(c)
				let att = []
				for (let d of c["datatypeProperties"]) {
					let obj = {}
					// for other ontologies cannot use label@en
					obj[d["labels"]["rdfs:label@en"]] = d["ranges"][0]
					att.push(obj)
				}
				
				for (let o of c["objectProperties"]) {
					let obj = {}
					obj[o["labels"]["rdfs:label@en"]] = o["ranges"] + "    "
					att.push(obj)
				}
				let data = {
					"name": c["labels"]["rdfs:label@en"],
					"iri": c["iri"],
					"attributes": att
				}
				let nc = this.getFabricOwlClass(data)
				this.canvas.add(nc)
				this.canvas.centerObject(nc)
				if (!up) {
					nc.top = maxTop + maxHeight + 125
				} else {
					nc.top = maxTop - 125 - nc.height
				}
				if (crc != null)
					nc.left = crc.left + crc.width + 50
				width+= nc.width+50
				nc.setCoords()
				crc = nc
				c["visualization"] = nc	
				nrc.push(c)
				
				let assoc = {}
				if (up) {
					assoc["sc"] = rc["visualization"]
					assoc["tc"] = nc
				} else {
					assoc["sc"] = nc
					assoc["tc"] = rc["visualization"]
				}
				assocs.push(assoc)
			}
			
			console.log(width + "width")
			for (let c of nrc) {
				if (nrc.length > 1) {
					//c["visualization"].left = c["visualization"].left - 500
					c["visualization"].setCoords()
				}
			}
			
			for (let p of assocs) {
				let assoc = this.getAssociation(p["sc"], p["tc"], "inherits", "test");
				this.canvas.add(assoc);
				this.updateAssociation(assoc, p["sc"], p["tc"], 0, 0, []);
				assoc.setCoords()
			}
			
			for (let c of nrc)
				this.canvas.bringToFront(c["visualization"])
			
			for (let c of rowClasses)
				this.canvas.bringToFront(c["visualization"])
			
		}
		if (nrc.length > 0)
			this.drawRow(nrc, up)
	}
	
	drawDiagram() {
		this.setState({ transition: true})
		fetch("/diagram/" + encodeURIComponent(this.props.iri) + "/" + this.state.maxDepth + "/" + this.state.maxChildren)
		  .then(res => res.json())
		  .then(
			(result) => {
				if (this.canvas != null) {
					this.canvas.clear()
					this.canvas.setHeight(750)
					this.canvas.setViewportTransform([1,0,0,1,0,0]); 
				} else {
					
					let tempWidth = 400
					if (this.wrapper != null && this.wrapper.current != null)
						tempWidth = this.wrapper.current.offsetWidth-20
					
					this.canvas = new fabric.Canvas('Canvas', {
						height: 750,
						width: tempWidth,
						preserveObjectStacking: true
					})
					window.addEventListener('resize', this.resizeCanvas, false)
					this.canvas.on('mouse:down', function(opt) {
						var evt = opt.e;
						this.isDragging = true;
						this.selection = false;
						this.lastPosX = evt.clientX;
						this.lastPosY = evt.clientY;
					});
					
					this.canvas.on('mouse:move', function(opt) {
						if (this.isDragging) {
							var e = opt.e;
							var vpt = this.viewportTransform;
							vpt[4] += e.clientX - this.lastPosX;
							vpt[5] += e.clientY - this.lastPosY;
							this.requestRenderAll();
							this.lastPosX = e.clientX;
							this.lastPosY = e.clientY;
						}
					});
					
					this.canvas.on('mouse:up', function(opt) {
					  // on mouse up we want to recalculate new interaction
					  // for all objects, so we call setViewportTransform
					  this.setViewportTransform(this.viewportTransform);
					  this.isDragging = false;
					  this.selection = true;
					  this.forEachObject(function(object){
						object.setCoords();
					  })
					});
				}
				
				let att = []
				for (let d of result["datatypeProperties"]) {
					let obj = {}
					// for other ontologies cannot use label@en
					obj[d["labels"]["rdfs:label@en"]] = d["ranges"][0]
					att.push(obj)
				}
				
				for (let o of result["objectProperties"]) {
					let obj = {}
					obj[o["labels"]["rdfs:label@en"]] = o["ranges"] + "    "
					att.push(obj)
				}
				
				// sample class
				let classData = {
					"name": result["labels"]["rdfs:label@en"],
					"iri": result["iri"],
					"attributes": att
				}
				
				let newClass = this.getFabricOwlClass(classData)
				this.canvas.add(newClass)
				this.canvas.centerObject(newClass)
				newClass.setCoords()
				
				result["visualization"] = newClass
				
				// iterate over the layers and classes
				this.drawRow([result], false)
				this.drawRow([result], true)
				
				this.canvas.forEachObject(object => {
					object.selectable = false;
					object.evented = false;
				});
				
				this.setState({ transition: false})
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error);
			})
	}
	
	componentDidUpdate(prevProps) {
		if (this.props.iri !== prevProps.iri) {
			this.drawDiagram()
		}
   }
   
	handleDepthChange = (e) => {
		this.setState({
		  maxDepth: e.target.value
		}, () => this.drawDiagram())
	}
	
	handleChildrenChange = (e) => {
		this.setState({
		  maxChildren: e.target.value
		}, () => this.drawDiagram())
	}
	
	render() {
		const { data, maxDepth, maxChildren, transition } = this.state;
		
		return (
			<div id="Visualization" ref={this.wrapper} >
				<div id="Controls">
					Max depth <select value={maxDepth} onChange={this.handleDepthChange}>
						{ Array.from({length: 10}, (x, i) => <option>{i}</option>) }
					</select> Max children (per node) <select value={maxChildren} onChange={this.handleChildrenChange}>
						{ Array.from({length: 26}, (x, i) => <option>{i}</option>) }
					</select>
				</div>
				<canvas id="Canvas" />
			</div>
		);
	}
}

class Search extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searching: "hide",
			data: {},
			type: "owl:Class",
			sType: "Classes"
		};
		this._onFocus = this._onFocus.bind(this)
		this._onBlur = this._onBlur.bind(this)
		this.handleChange = this.handleChange.bind(this)
	}
	
	handleChange(e) {
		if (e.target.value.length > 2) {
			fetch("/search/" + e.target.value + "/" + this.state.type + "/No Limit")
		  .then(res => res.json())
		  .then(
			(result) => {
				this.setState({data: result});
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
			  console.log(error);
			})
		} else {
			this.setState({data: {}});
		}
	}
	
	_onBlur() {
		this.setState({
			searching: "hide"
		});
	}
	
	_onFocus(e) {
		this.setState({
			searching: "show"
		});
	}
	
	handleOnChange = e => {
		document.getElementById("searchBar").value = "";
		this.setState({
		  type: e.target.value,
		  sType: e.target.options[e.target.selectedIndex].text,
		  data: {}
		})
	}
	
	handleMouseDown = e => {
		document.getElementById("searchBar").value = "";
		this.setState({
			data: {}
		});
		this.props.onClick(e);
	}
	
	render() {
		const { searching, data, type, sType } = this.state;
		
		return (<div>
			<div id="overlay" className={searching === "show" ? "showOverlay" : null}></div>
			<input type="text" id="searchBar" onFocus={this._onFocus} onBlur={this._onBlur} onChange={this.handleChange}/>
			<div id="results" className={searching}>
				<ul id="searchResults">
					{Array.isArray(data.elements) ? data.elements.map((element) => <li><a href="#" type={sType} iri={element.iri} displayLabel={(element.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? element.iri : (element.labels["rdfs:label@en"] == undefined) ? element.iri : element.labels["rdfs:label@en"])) : element.labels[this.props.displayLabel] } onMouseDown={this.handleMouseDown}>
						{(element.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? element.iri : (element.labels["rdfs:label@en"] == undefined) ? element.iri : element.labels["rdfs:label@en"])) : element.labels[this.props.displayLabel] }
						</a>
					</li>) : null}
				</ul>
			</div>
			<select onChange={this.handleOnChange}>
				<option value="owl:Class">Classes</option>
				<option value="owl:DatatypeProperty">DatatypeProperties</option>
				<option value="owl:ObjectProperty">ObjectProperties</option>
				<option value="owl:NamedIndividual">NamedIndividuals</option>
			</select>
		</div>);
	}
}

class ContentView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {}
		}
	}
	
	componentDidMount() {
		fetch("/content/" + encodeURIComponent(this.props.iri))
      .then(res => res.json())
      .then(
        (result) => {
		  this.setState({data: result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
		  console.log(error);
        })
	}
	
	componentDidUpdate(prevProps) {
		
		if (this.props.iri !== prevProps.iri) {
		console.log("/content/" + encodeURIComponent(this.props.iri));
       fetch("/content/" + encodeURIComponent(this.props.iri))
      .then(res => res.json())
      .then(
        (result) => {
		  this.setState({data: result});
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
		  console.log(error);
        }
      )
		}
   }
	
	render() {
		const { data } = this.state;
		return <div><span className={data.type === "http://www.w3.org/2002/07/owl#Class" ? "badge blue" : data.type === "http://www.w3.org/2002/07/owl#DatatypeProperty" ? "badge yellow" : data.type === "http://www.w3.org/2002/07/owl#ObjectProperty" ? "badge green" : "badge"}>{data.type}</span><h3>{this.props.iri}</h3><h1>{this.props.displayName}</h1>
		<ul>
		{Array.isArray(data.comments) ? data.comments.map((comment) => <li>{comment}</li>) : null}
		</ul>
		{data.type === "http://www.w3.org/2002/07/owl#Class" ? 
		<Visualization iri={this.props.iri} />
		: null }
		
		
		
		
		{data.type === "http://www.w3.org/2002/07/owl#Class" ? 
		<h3>Datatype Properties</h3>
		: null }
		<table>
			{Array.isArray(data.datatypeProperties) ? data.datatypeProperties.map((prop) => <tr><td><a href="#" type="DatatypeProperties" displayLabel={(prop.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? prop.iri : (prop.labels["rdfs:label@en"] == undefined) ? prop.iri : prop.labels["rdfs:label@en"])) : prop.labels[this.props.displayLabel] } iri={prop.iri} onClick={(e) => this.props.onClick(e)}>
		{ (prop.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? prop.iri : (prop.labels["rdfs:label@en"] == undefined) ? prop.iri : prop.labels["rdfs:label@en"])) : prop.labels[this.props.displayLabel] }
		</a></td><td> { Array.isArray(prop.ranges) ? prop.ranges.map((range) => range) : null}
		</td></tr>) : null}
		</table>
		{data.type === "http://www.w3.org/2002/07/owl#Class" ? 
		<h3>Object Properties</h3>
		: null }
		<table>
		{Array.isArray(data.objectProperties) ? data.objectProperties.map((prop) => <tr><td><a href="#" type="ObjectProperties" displayLabel={(prop.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? prop.iri : (prop.labels["rdfs:label@en"] == undefined) ? prop.iri : prop.labels["rdfs:label@en"])) : prop.labels[this.props.displayLabel] } iri={prop.iri} onClick={(e) => this.props.onClick(e)}>
		{ (prop.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? prop.iri : (prop.labels["rdfs:label@en"] == undefined) ? prop.iri : prop.labels["rdfs:label@en"])) : prop.labels[this.props.displayLabel] }
		</a></td><td> { Array.isArray(prop.ranges) ? prop.ranges.map((range) => <a href="#" type="Classes" iri={range.iri} onClick={(e) => this.props.onClick(e)} displayLabel={(range.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? range.iri : (range.labels["rdfs:label@en"] == undefined) ? range.iri : range.labels["rdfs:label@en"])) : range.labels[this.props.displayLabel]}>
			{(range.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? range.iri : (range.labels["rdfs:label@en"] == undefined) ? range.iri : range.labels["rdfs:label@en"])) : range.labels[this.props.displayLabel]}
		</a>) : null}</td>
		</tr>) : null}
		</table>
		{data.type != "http://www.w3.org/2002/07/owl#NamedIndividual" ? 
		<h3>Individuals</h3> : null }
		<table>
		{Array.isArray(data.individuals) ? data.individuals.map((individual) => <tr><td><a href="#" type="NamedIndividuals" displayLabel={ (individual.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? individual.iri : (individual.labels["rdfs:label@en"] == undefined) ? individual.iri : individual.labels["rdfs:label@en"])) : individual.labels[this.props.displayLabel] } iri={individual.iri} onClick={(e) => this.props.onClick(e)}>
		{ (individual.labels[this.props.displayLabel] == undefined) ? ((this.props.displayLabel == "iri" ? individual.iri : (individual.labels["rdfs:label@en"] == undefined) ? individual.iri : individual.labels["rdfs:label@en"])) : individual.labels[this.props.displayLabel] }
		</a></td></tr>) : null}
		</table>
		</div>
	}
}

// https://blog.logrocket.com/4-ways-to-render-large-lists-in-react/
class RecursiveTreeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
	  name: false,
	  selectOptionValue: "rdfs:label@en",
      data: {},
	  display: "IdentifiedObject",
	  sortOrder: "a-z",
	  content: "http://www.iec.ch/TC57/CIM#IdentifiedObject",
	  type: "Classes"
    };
	this.sortChildren = this.sortChildren.bind(this);
  }

  sortChildren(root, sort) {
	  if (sort) 
		root.sort((a, b) => a.iri.localeCompare(b.iri));
	  else 
		root.sort((b, a) => a.iri.localeCompare(b.iri)); 
	  if (Array.isArray(root)) {
		root.map((node) => this.sortChildren(node.children, sort));
	  } else {
		  console.log(root);
	  }
  }
  
  componentDidMount() {
	  fetch("/treeinfo")
      .then(res => res.json())
      .then(
        (result) => {
		  this.sortChildren(result.classes, true);
		  this.sortChildren(result.datatypeProperties, true);
		  this.sortChildren(result.objectProperties, true);
		  this.sortChildren(result.namedIndividuals, true);
		  
          this.setState({
            isLoaded: true,
            error: false,
			name: false,
			data: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
			console.log(error);
          this.setState({
            isLoaded: true,
            error: true
          });
        }
      )
  }
  
  handleType = (e) => {
	  this.setState({
		type: e.target.value  
	  })
  }
  
   handleOnChange = (e) => {
    this.setState({
      selectOptionValue: e.target.value
    })
  }
  
  // sorting based on iri
  handleSort = (e) => {
	  this.setState({
		sortOrder: e.target.value  
	  });
	  if (e.target.value === "a-z") {
		  this.sortChildren(this.state.data.classes, true); 
		  this.sortChildren(this.state.data.datatypeProperties, true);
		  this.sortChildren(this.state.data.objectProperties, true);
		  this.sortChildren(this.state.data.namedIndividuals, true);
	  } else {
		 this.sortChildren(this.state.data.classes, false);
	  this.sortChildren(this.state.data.datatypeProperties, false);
		  this.sortChildren(this.state.data.objectProperties, false);
		  this.sortChildren(this.state.data.namedIndividuals, false);		 
	  }
  }
  
  getContent = (e) => {
	  this.setState({
	  content: e.target.getAttribute("iri"),
	  display: e.target.getAttribute("displayName")});
  }
  
  
  
  setContent = (e) => {
	   this.setState({
	  content: e.target.getAttribute("iri"),
	   display: e.target.getAttribute("displayLabel")});
	   
	   
	   this.setState({
	   type: e.target.getAttribute("type")})
  }

  
  render() {
	  const { error, isLoaded, data, name, selectOptionValue, sortOrder, content, display, type } = this.state;
	  const renderTree = (nodes) => (
	  
	<li><a href="#" onClick={this.getContent} iri={nodes.iri} displayName={ (nodes.labels[selectOptionValue] == undefined) ? ((selectOptionValue == "iri" ? nodes.iri : (nodes.labels["rdfs:label@en"] == undefined) ? nodes.iri : nodes.labels["rdfs:label@en"])) : nodes.labels[selectOptionValue] }> 
	{ (nodes.labels[selectOptionValue] == undefined) ? ((selectOptionValue == "iri" ? nodes.iri : (nodes.labels["rdfs:label@en"] == undefined) ? nodes.iri : nodes.labels["rdfs:label@en"])) : nodes.labels[selectOptionValue] }
			</a><ul>
				{Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
			</ul>
		</li>
	  );
	  
	  return (
		<div id="wrapper">
			<div id="header">
				<div id="left">
					<a href="https://www.puffinsemantics.com"><ReactLogo/></a><div class="ont">CIM Ontology</div><Search displayLabel={selectOptionValue} onClick={(e) => this.setContent(e)}/>
				</div>
				<div id="right"><div id="display">
					<select onChange={this.handleOnChange} value={selectOptionValue}>
						<option>iri</option>
						{Array.isArray(data.labels) ? data.labels.map((label) => <option>{label}</option>) : null}
					</select></div>
				<div id="version"><span className="badge">61970.17v38_61968.13v13_62325.03v17a</span></div></div>
			</div>
			<div id="container">
				<div id="navWrap">
					<div id="selectors">
						<div>
						Show <select onChange={this.handleType} value={type}>
							<option>Classes</option>
							<option>DatatypeProperties</option>
							<option>ObjectProperties</option>
							<option>NamedIndividuals</option>
						</select>
						</div>
						<div>
						Sort order <select onChange={this.handleSort} value={sortOrder}>
							<option>a-z</option>
							<option>z-a</option>
						</select>
						</div>
					</div>
					<div id="navigation">
						{type==="Classes" ? 
						<ul className="c">
							{Array.isArray(data.classes) ? data.classes.map((node) => renderTree(node)) : null}
						</ul> : null}
						
						{type==="DatatypeProperties" ? 
						<ul className="d">
							{Array.isArray(data.datatypeProperties) ? data.datatypeProperties.map((node) => renderTree(node)) : null}
						</ul> : null}
						
						{type==="ObjectProperties" ? 
						<ul className="o">
							{Array.isArray(data.objectProperties) ? data.objectProperties.map((node) => renderTree(node)) : null}
						</ul> : null}
						
						{type==="NamedIndividuals" ? 
						<ul>
							{Array.isArray(data.namedIndividuals) ? data.namedIndividuals.map((node) => renderTree(node)) : null}
						</ul> : null}
					</div>
				</div>
				<div id="content">
					<ContentView iri={content} displayName={display} displayLabel={selectOptionValue} onClick={(e) => this.setContent(e)}/>
				</div>
			</div>
		</div>
	  );
  }
}

export default RecursiveTreeView;