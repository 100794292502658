import logo from './logo.svg';
import './App.css';
import IconExpansionTreeView from './Navigation.js'
import RecursiveTreeView from './Navigation2.js'

function App() {
  return (
    <div className="App">
		<RecursiveTreeView />
    </div>
  );
}

export default App;
